import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retryWhen, delay, take, timeout, switchMap } from 'rxjs/operators';
import { throwError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  private API_TIMEOUT = 20000;

  /**
   * Method to intercept the http requests
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let updatedRequest;
    updatedRequest = request.clone({
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        Authorization: 'Basic ' + btoa('InternetUser' + ':' + 'Covid19!Internet')
      }
    });
    return next.handle(updatedRequest).pipe(timeout(this.API_TIMEOUT), retryWhen(errors =>
      errors.pipe(
        delay(2000),
        take(3),
        switchMap((error, index) => index === 2 ? throwError(error) : of(error))
      )
    ));
  }
}
