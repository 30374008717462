import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private showLoaderSubject = new BehaviorSubject<string>("");
  public loading = this.showLoaderSubject.asObservable();

  constructor() { }

  public showLoader(msg: string) {
    this.showLoaderSubject.next(msg);
  }

  public hideLoader() {
    this.showLoaderSubject.next('');
  }
}
