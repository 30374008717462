import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import { ComponentType } from '../../enum/componentType.enum';

@Injectable({
  providedIn: 'root'
})
export class CurrentComponentService {

  private selectedComponent = new Subject<ComponentType>();

  constructor() { }

  publishSelectedComponent(data: ComponentType) {
    this.selectedComponent.next(data);
  }

  getSelectedComponent(): Subject<ComponentType> {
    return this.selectedComponent;
  }
}
