import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlertController, MenuController, ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ComponentType } from './enum/componentType.enum';
import { CurrentComponentService } from './services/current-component/current-component.service';
import { StorageService } from './services/storage/storage.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { LoadingService } from './services/loading/loading.service';
import { AppConstantsService } from './services/app-constants/app-constants.service';
import { ConnectionService } from 'ng-connection-service';
import { ManuType } from './enum/menuType.enum';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  public ComponentType = ComponentType;
  public componentType = ComponentType.LANDING;
  public isConnected = true;
  public loadingMsg: string = "";
  public menuType = ManuType.OPEN_ACTIVITIES;
  public MenuType = ManuType;
  public showSpinner: boolean = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private currentComponentService: CurrentComponentService,
    public storageService: StorageService,
    private screenOrientation: ScreenOrientation,
    private loadingService: LoadingService,
    public appConstantsService: AppConstantsService,
    private connectionService: ConnectionService,
    private menuController: MenuController,
    private alertController: AlertController,
    private cdRef: ChangeDetectorRef
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.appConstantsService.isMobileApp()) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY);
      }
    });
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.loadingService.hideLoader();
      } else {
        this.loadingService.showLoader('You\'re Offline.\n Checking for network connection...');
      }
    });
  }

  ngOnInit() {
    this.currentComponentService.getSelectedComponent().subscribe((component) => {
      this.componentType = component;
    });

    this.loadingService.loading.subscribe((loadingMsg: string) => {
      this.loadingMsg = loadingMsg;
      let showLoader = (loadingMsg && loadingMsg.length > 0);
      if (showLoader !== this.showSpinner) {
        this.showSpinner = showLoader;
        this.cdRef.detectChanges();
      }
    });
  }

  ngAfterViewInit() {
    this.menuController.swipeGesture(false);
  }

  selectedComponent(event: any) {
    this.componentType = event;
    this.currentComponentService.publishSelectedComponent(event);
  }

  async logout() {
    const alert = this.alertController.create({
      message: 'Are you sure you want to log out?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
          }
        },
        {
          text: 'Yes',
          handler: () => {
            this.menuController.close();
            this.storageService.clearStorageByKey('rememberMe');
            this.storageService.clearStorageByKey('userId');
            this.storageService.clearStorageByKey('lastSelectedAreaId');
            this.storageService.clearStorageByKey('currentPreference');
            this.selectedComponent(ComponentType.LOGIN);
          }
        }
      ]
    });
    (await alert).present();
  }

  closeMenu() {
    this.menuController.close();
  }

  loadOnGoingActivities() {
    this.menuType = ManuType.OPEN_ACTIVITIES;
    this.selectedComponent(ComponentType.OPEN_ACTIVITIES);
  }

  loadChangePassword() {
    this.menuType = ManuType.CHANGE_PASSWORD;
    this.selectedComponent(ComponentType.CHANGE_PASSWORD);
  }

  async onFeedbackClicked() {
    this.closeMenu();
    this.selectedComponent(ComponentType.FEEDBACK);
  }

  public handleFailedImages() {
    this.closeMenu();
    this.selectedComponent(ComponentType.FAILED_IMAGES);
  }

}
